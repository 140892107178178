<template>
  <div class="w-full h-full pt-5">
    <div class="flex">
      <back-button
        label="Back"
        @onClick="$router.back()"
        variant="secondary"
        class="mr-4"
      />
      <div
        class="mt-4"
        style="border-left: 1px solid #878e99; height: 1.5rem"
      />
      <h1 class="text-xl text-left font-extrabold ml-4 mt-3 w-auto">
        Terminal Pay
      </h1>
      <div class="w-7/12 mt-4 ml-8">
        <Breadcrumb :items="breadcrumbs" />
      </div>
    </div>
    <div style="height: 100%" v-if="loadingData">
      <loader size="xxs" :loader-image="false" />
    </div>
    <div v-else>
      <div class="px-3">
        <card class="p-5 mt-6">
          <div class="border rounded border-romanSilver border-dashed p-3">
            <p class="font-bold mb-8">Pay Run Information</p>
            <div class="flex w-full gap-3">
              <card class="p-3 w-52">
                <h6 class="uppercase mb-2 font-bold text-sm text-flame">
                  Pay run Type
                </h6>
                <h6 class="font-semibold text-sm text-jet uppercase">
                  {{ payrunType }}
                </h6>
              </card>
              <card class="p-3 ml-2">
                <h6
                  class="font-semibold mt-2 text-sm text-blueCrayola uppercase"
                >
                  Description
                </h6>
                <div class="flex">
                  <div class="flex">
                    <h6 class="font-semibold text-sm text-jet uppercase">
                      {{ payrunData.description }}
                    </h6>
                  </div>
                </div>
              </card>
            </div>
          </div>

          <div class="flex justify-between mt-5">
            <div class="flex gap-5 flex-1">
              <card class="w-auto p-3 pr-8">
                <p class="uppercase text-romanSilver font-extrabold text-xs">
                  No. of Employees
                </p>
                <p class="text-lg text-darkPurple font-bold uppercase">
                  {{ payrunData.employees }}
                </p>
              </card>
              <card class="w-auto p-3 pr-8">
                <p class="uppercase text-romanSilver font-extrabold text-xs">
                  Total Pay
                </p>
                <p class="text-lg text-darkPurple font-bold uppercase">
                  {{ convertToCurrency(payrunData.totalNetPay) }}
                </p>
              </card>
            </div>
            <div class="flex items-center ml-4">
              <Button
                background-color="none"
                class="text-white mr-5 rounded"
                :background="appearance.buttonColor || defaultBTNColor"
                border="1px solid #F15A29"
                @click="submitTerminalPay('review')"
                :disabled="selectedEmployees.length === 0"
              >
                Submit Payrun
              </Button>
              <Button
                class="bg-white mr-3 text-dynamicBackBtn border border-solid border-dynamicBackBtn"
                style-name="width:inherit"
                :disabled="selectedEmployees.length === 0"
                @click="submitTerminalPay('draft')"
              >
                Save as Draft
              </Button>
            </div>
          </div>
          <div class="border w-full bg-romanSilver mt-8" />
        </card>
        <card class="mt-5">
          <sTable
            :headers="headers"
            :items="tableData"
            style="width:100%; height:auto"
            class="w-full"
            aria-label="table"
            has-number
            :loading="false"
            :has-checkbox="true"
            @rowSelected="processEmployees"
          >
            <template v-slot:item="{ item }">
              <div v-if="item.photo" class="flex items-center gap-3">
                <img
                  class=""
                  :src="item.data.photo"
                  alt="photo"
                  v-if="item.data.photo"
                  style="height: 30px; width: 30px; border-radius: 5px"
                />
                <div
                  style="height: 35px; width: 35px; border-radius: 5px"
                  class="text-blueCrayola border text-center font-semibold pt-2"
                  v-else
                >
                  {{ $getInitials(`${item.data.name}`) }}
                </div>
              </div>

              <div
                class="tw-font-bold flex flex-col"
                style="color: #525252"
                v-else-if="item.name"
              >
                {{ item.data.name }}
              </div>

              <span v-else-if="item.regularEarnings" class="tw-text-center">
                {{
                  !item.data.regularEarnings
                    ? "Nil"
                    : convertToCurrency(item.data.regularEarnings)
                }}
              </span>
              <span v-else-if="item.additions" class="flex tw-text-center">
                <Menu
                  right
                  top="-300"
                  margin="46"
                  class="p-0"
                  v-if="item.data.additions"
                >
                  <template v-slot:title>
                    <div class="underline text-blueCrayola">
                      {{ convertToCurrency(item.data.additions) }}
                    </div>
                  </template>
                  <div style="width: 250px; padding: 10px 5px">
                    <scroll-container height="200px">
                      <div
                        class="flex flex-col py-2 px-3"
                        v-for="(additions, index) in item.data
                          .monthlyAdditionsPayItems"
                        :key="index"
                      >
                        <p class="pt-1 flex flex-grow text-sm font-semibold">
                          {{ additions.name }}
                        </p>
                        <p class="pt-1 flex text-xs text-romanSilver">
                          {{ convertToCurrency(additions.amount) }}
                        </p>
                      </div>
                    </scroll-container>
                  </div>
                </Menu>
                <span v-else> {{ convertToCurrency(0) }} </span>
                <span class="">
                  <Icon
                    class-name="text-flame cursor-pointer ml-4 self-center"
                    size="xms"
                    @click.native="handleClick('addition', item.data.userId, item.data.month, item.data.year)"
                    icon-name="addition"
                  />
                </span>
              </span>
              <span v-else-if="item.deductions" class="flex tw-text-center">
                <Menu
                  right
                  top="-300"
                  margin="54"
                  class="p-0"
                  v-if="item.data.deductions"
                >
                  <template v-slot:title>
                    <div class="underline text-desire">
                      ({{ convertToCurrency(item.data.deductions) }})
                    </div>
                  </template>
                  <div style="width: 250px; padding: 10px 5px">
                    <scroll-container height="200px">
                      <div
                        class="flex flex-col py-2 px-3"
                        v-for="(deductions, index) in item.data
                          .monthlyDeductionsPayItems"
                        :key="index"
                      >
                        <p class="pt-1 flex flex-grow text-sm font-semibold">
                          {{ deductions.name }}
                        </p>
                        <p class="pt-1 flex text-xs text-romanSilver">
                          {{ convertToCurrency(deductions.amount) }}
                        </p>
                      </div>
                    </scroll-container>
                  </div>
                </Menu>
                <span v-else>
                  {{ convertToCurrency(0) }}
                </span>
                <span>
                  <Icon
                    class-name="text-flame cursor-pointer ml-4 self-center"
                    size="xms"
                    @click.native="handleClick('deduction', item.data.userId, item.data.month, item.data.year)"
                    icon-name="addition"
                  />
                </span>
              </span>
              <span v-else-if="item.monthlyPAYE" class="tw-text-center">
                {{
                  item.data.monthlyPAYE
                    ? convertToCurrency(item.data.monthlyPAYE)
                    : 0
                }}
              </span>
              <span v-else-if="item.employeePension" class="tw-text-center">
                {{
                  item.data.employeePension
                    ? convertToCurrency(item.data.employeePension)
                    : 0
                }}
              </span>
              <span v-else-if="item.nhfAmount" class="tw-text-center">
                {{
                  item.data.nhfAmount
                    ? convertToCurrency(item.data.nhfAmount)
                    : 0
                }}
              </span>
              <span v-else-if="item.netPay" class="grosspay">
                {{ convertToCurrency(item.data.netPay) }}
              </span>
            </template>
          </sTable>
        </card>
      </div>
    </div>

    <edit-outstanding
      :open-modal="openModal"
      :modal-type="modalType"
      :user-id="userId"
      :month = "month"
      :year = "year"
      @close="handleClose"
    />

  </div>
</template>

<script>
/* eslint-disable no-console */
import { mapState } from "vuex";

import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import STable from "@scelloo/cloudenly-ui/src/components/table";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import Card from "@/components/Card";
import Button from "@/components/Button";
import ScrollContainer from "@/components/ScrollContainer";
import Menu from "@/components/Menu";

export default {
  components: {
    BackButton,
    Card,
    ScrollContainer,
    Button,
    Breadcrumb,
    Menu,
    STable,
    EditOutstanding: () => import("./EditOutstanding")
  },
  data() {
    return {
      openModal: false,
      openEditModal: false,
      openTransactionModal: false,
      loadingData: true,
      confirmModal: false,
      delModal: false,
      showOfflineModal: false,
      showOfflineSidebar: false,
      showCancelModal: false,
      showPasswordModal: false,
      modalType: "",
      userId: "",
      password: "",
      checkboxes: [],
      disableBtn: false,
      decryptPassword: false,
      offline: {
        paidAt: "",
        paymentSource: "",
        paymentTransactionId: "",
      },
      payrunId: "",
      netPaySum: 0,
      paymentType: "",
      month: "",
      year: "",
      getToday: "",
      seeComments: false,
      payCycleYear: "",
      payrunData: {},
      payrunType: "",
      payment: { transferedAmount: 0, totalAmount: 0, totalCharges: 0 },
      tableData: [],
      approvalsData: {},
      ActionType: "Approve pay run",
      approverDetails: {},
      requestProgress: [],
      approversNumber: "0",
      selectedEmployees: [],
      comment: "",
      response: "",
      responseBg: "",
      success: false,
      breadcrumbs: [
        { disabled: false, text: "Payroll", href: "Payroll", id: "Payroll" },
        { disabled: false, text: "Payrun", href: "Payrun", id: "Payrun" },
        {
          disabled: false,
          text: "Off-Cycle Pay",
          href: "Payrun",
          id: "Off-Cycle",
        },
        {
          disabled: false,
          text: "Terminal Pay",
          href: "TerminalPay",
          id: "TerminalPay",
        },
      ],
      categoryArray: [
        {
          name: "Approve pay run",
          value: "Approve pay run",
          radioName: "job",
        },
        {
          name: "Reject pay run",
          value: "Reject pay run",
          radioName: "job",
        },
        {
          name: "Return for review",
          value: "Return for review",
          radioName: "job",
        },
      ],
      payload: [],
      headers: [
        { title: "", value: "photo", width: 5 },
        { title: "Employee", value: "name", width: 15 },
        { title: "Regular Earnings", value: "regularEarnings", width: 12 },
        { title: "Additions", value: "additions", width: 12 },
        { title: "Deductions", value: "deductions", width: 12 },
        { title: "PAYE", value: "monthlyPAYE", width: 12 },
        { title: "Pension", value: "employeePension", width: 12 },
        { title: "NHF", value: "nhfAmount", width: 8 },
        { title: "Net Pay", value: "netPay", width: 12 },
      ],
    };
  },
  computed: {
    hasSubscriptionExpired() {
      return this.$store.getters.hasSubscriptionExpired;
    },
    ...mapState({
      banks: (state) =>
        state.Banks.map((bank) => ({
          ...bank,
          id: bank.code,
        })),
    }),
    defaultBTNColor: (state) => state.orgAppearance.defaultBtnColor,
    appearance: (state) => state.orgAppearance.orgAppearanceData,
  },
  methods: {
    handleClose() {
      this.openModal = false;
      this.modalType = "";
      this.tableData = [];
      this.getProcessedEmployees();
    },

    handleEditClose() {
      this.getEmployees();
      this.openEditModal = false;
      this.modalType = "";
    },
    handleEdit(type, id) {
      this.modalType = type;
      this.userId = id;
      this.openEditModal = true;
    },

    processEmployees(value) {
      this.selectedEmployees = value;
    },

    handleClick(type, id, month, year) {
      this.modalType = type;
      this.userId = id;
      this.month = month.toString();
      this.year = year.toString();
      this.openModal = true;
    },

    submitTerminalPay(status) {
      this.selectedEmployees.forEach((data) => {
        this.payload.push({
          employeeId: data.id,
          orgId: this.$orgId,
          createdBy: this.$AuthUser.id,
          payType: this.payrunType,
          type: "terminalPay",
          payFrequency: data.payFrequency,
          splitPosition: data.splitPosition,
          month: data.month,
          paymentType: data.paymentType,
          year: data.year,
          payrollStatus: status,
        });
      });
      this.$_submitTerminalPay(this.payload)
        .then((result) => {
          this.disableBtn = false;
          this.$toasted.success("Pay run created successfully", {
            duration: 5000,
          });

          if (status === "review") {
            this.$router.push({
              name: "terminalPay",
              query: { currentTab: "Under Review" },
            });
          }
          else {
            this.$router.push({
              name: "terminalPay",
              query: { currentTab: "Drafts" },
            });
          }
          return result;
        })
        .catch((err) => {
          this.disableBtn = false;
          this.$toasted.error(
            "An error occured, Please contact an IT personnel",
            { duration: 5000 }
          );
          return err;
        });
    },

    submitDraftTerminalPay() {
        const getPayrun = {
          payrun: {},
          employees: [],
          type: 'terminalPay'
        };
        getPayrun.payrun.id = this.$route.params.id;
        getPayrun.payrun.payrollStatus = 'draft';

        // eslint-disable-next-line array-callback-return
      this.selectedEmployees.forEach((data) => {
        getPayrun.employees.push(data.id);
      });

      this.$_editOnePayrun(getPayrun)
        .then((result) => {
          this.disableBtn = false;
          this.$toasted.success("Terminal Pay run created successfully", {
            duration: 5000,
          });
            this.$router.push({name : "terminalPay", query: { currentTab: 'Drafts' }});
          return result;
        })
        .catch((err) => {
          this.disableBtn = false;
          this.$toasted.error(
            "An error occured, Please contact an IT personnel",
            { duration: 5000 }
          );
          return err;
        });
    },

    getProcessedEmployees() {
      const processedId = this.$route.params.id
      const payload = { processedId, params: {processed: true} }
      this.$_getProcessedEmployees(payload).then((response) => {
        this.payrunType = response.data.payrunType;
        this.payrunData.description = response.data.description;
        this.payrunData.employees = response.data.count;
        this.payrunData.totalNetPay = response.data.totalPay;

        response.data.processedEmployees.forEach((employee) => {
          this.tableData.push({
            id: employee.id,
            userId: employee.userId,
            name: `${employee.fname} ${employee.lname}`,
            payFrequency: employee.payruns.payrun.payFrequency,
            splitPosition: employee.payruns.payrun.paySplitPosition,
            paymentType: employee.payruns.payrun.paymentType,
            month: employee.payruns.payrun.month,
            year: employee.payruns.payrun.year,
            photo: employee.photo,
            regularEarnings: employee.payruns.grossEarnings,
            additions: employee.addition,
            deductions: employee.deduction,
            monthlyPAYE: employee.payruns.paye,
            employeePension: employee.payruns.totalPension,
            nhfAmount: employee.payruns.nhfAmount,
            netPay: employee.payruns.netEarnings,
            monthlyDeductionsPayItems: employee.outstandings.filter((x) => x.type === 'deduction'),
            monthlyAdditionsPayItems: employee.outstandings.filter((x) => x.type === 'addition')
          });
        });
        this.loadingData = false;
      });
    }
  },

  mounted() {
    this.getProcessedEmployees();
  },
};
</script>

<style scoped>
.bgCarrot {
  background-color: rgba(233, 147, 35, 0.08);
}
.widthInherited {
  width: inherit;
}
.buttons {
  width: 126px;
  height: 44px;
  border-radius: 5px;
}
.grosspay {
  background: #2176ff26;
  padding: 0.3rem 0.7rem;
  color: #2176ff;
  border-radius: 5px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
